import React from 'react';
import dataText from '../components/gui/gui-typo/data';
import StandardPage from '../components/page/page-standard';
import '../components/site-map-page/site-map-page.scss';

const pageName = 'site-map-page';

const itemsData = [
  {
    title: 'Главная',
  },
  {
    title: 'Услуги',
    items: [
      {
        title: 'Комплекс',
      },
      {
        title: 'Годовые пакеты',
      },
      {
        title: 'Список услуг',
      },
    ],
  },
  {
    title: 'Грумеры',
    items: [
      {
        title: 'Страница грумера',
      },
    ],
  },
  {
    title: 'Подарочные карты',
  },
  {
    title: 'О нас',
  },
  {
    title: 'Контакты',
  },
  {
    title: 'Личный кабинет',
    items: [
      {
        title: 'Профиль',
      },
      {
        title: 'История записей',
      },
      {
        title: 'Покупки',
      },
      {
        title: 'Личные данные',
      },
      {
        title: 'Настройки',
      },
    ],
  },
  {
    title: 'Вакансии',
  },
  {
    title: 'FAQ',
  },
  {
    title: 'Бонусная система',
  },
  {
    title: 'Сертификаты',
  },
  {
    title: 'Персональные скидки',
  },
  {
    title: 'Обработка персональных данных',
  },
  {
    title: 'Публична оферта',
  },
  {
    title: 'Политика Cookie',
  },

];

itemsData.forEach((item) => {
  if (!item.text) {
    // eslint-disable-next-line no-param-reassign
    item.text = dataText.listItem;
  }
  if (!item.href) {
    // eslint-disable-next-line no-param-reassign
    item.href = '/';
  }

  if (item.items) {
    // eslint-disable-next-line no-shadow
    item.items.forEach((item) => {
      if (!item.text) {
        // eslint-disable-next-line no-param-reassign
        item.text = dataText.listItem;
      }
      if (!item.href) {
        // eslint-disable-next-line no-param-reassign
        item.href = '/';
      }
    });
  }
});

// todo alex заменить на рекурсивную функцию. Вспомнить про вложенный список в GUI
const SiteMapPage = () => (
  <StandardPage pageName={pageName} title="Карта сайта">


    <ol className="site-map-list">

      {/* eslint-disable-next-line no-shadow,react/no-array-index-key */}
      {itemsData.map((item, key) => {
        const { items } = item;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <li className="site-map-list__item" key={key}>
            <div className="site-map-list__item-title">
              <a href={item.href}>{item.title}</a>
            </div>

            {items && (
              <ol className="site-map-list">

                {/* eslint-disable-next-line no-shadow,react/no-array-index-key */}
                {items.map((item, key) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className="site-map-list__item" key={key}>
                    <div className="site-map-list__item-title site-map-list__item-title_small">
                      <a href={item.href}>{item.title}</a>
                    </div>
                  </li>
                ))}

              </ol>
            )}
          </li>
        );
      })}

    </ol>

  </StandardPage>
);

export default SiteMapPage;
